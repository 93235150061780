import { PageantRegistration } from "API/Generated/PageantRegistration";
import { ReceivedScores } from "API/Generated/ReceivedScores";
import { RegisteredContestantAwardRelationship } from "API/Generated/RegisteredContestantAwardRelationship";
import { PageantRegistrationRequest, PictureModel, ReceivedScore, ReceivedScoreBaseModel, RegisteredPageantContestantModel } from "API/Generated/data-contracts";
import { PageantAgeGroupBaseModel } from "helpers/PageantHelper";
import { makeObservable, observable, action } from "mobx";
import { makePersistable } from "mobx-persist-store";



class PageantRegistrationStore {
    PageantRegistration: PageantRegistration = new PageantRegistration();
    CurrentPageantRegistration: RegisteredPageantContestantModel;
    ShowRegistrationForm: boolean = false;
    AllowEdit: boolean = false;
    AgeGroups: PageantAgeGroupBaseModel[] = [];
    SupremeGroups: PageantAgeGroupBaseModel[] = [];
    RegisteredContestantAwardRelationships: RegisteredContestantAwardRelationship[] = [];
    ReceivedScore: ReceivedScoreBaseModel[] = [];
    ReceivedScoreService: ReceivedScores = new ReceivedScores();



    constructor(currentPageantRegistration: RegisteredPageantContestantModel, showRegistrationForm: boolean) {
        makeObservable(this, {
            CurrentPageantRegistration: observable,
            ShowRegistrationForm: observable,
            AllowEdit: observable,
            AgeGroups: observable,
            setAgeGroups: action,
            SupremeGroups: observable,
            setSupremeGroups: action,
            setAllowEdit: action,
            RegisteredContestantAwardRelationships: observable,
            saveScores: action,
            loadScores: action,
            getTotalScoresWithDropsForContestantAsyncAll: action, // Add this line
        })
        // makePersistable(this, { name: 'PageantRegistration', properties: ['CurrentPageantRegistration', 'ShowRegistrationForm', 'AllowEdit', 'AgeGroups', 'SupremeGroups']})

        this.CurrentPageantRegistration = currentPageantRegistration;
    }


    setAgeGroups(ageGroups: PageantAgeGroupBaseModel[]) {
        this.AgeGroups = ageGroups;
    }

    setSupremeGroups(supremeGroups: PageantAgeGroupBaseModel[]) {
        this.SupremeGroups = supremeGroups;
    }

    async savePageantRegistration(model: PageantRegistrationRequest): Promise<boolean> {
        try {
            if (model.contestantUserModel) {
                model.contestantUserModel.registeredPageantContestants = undefined;
            }

            const pageantRegistrationResult = model.pageantRegistrationModel?.id && model.pageantRegistrationModel?.id > 0
                ? await this.PageantRegistration.patchPageantRegistration(model)
                : await this.PageantRegistration.addPageantRegistration(model);

            const responseData = pageantRegistrationResult.data.data;
            return responseData !== null;
        }
        catch (error) {
            console.error("Error while saving pageant registration:", error);
            throw new Error("Failed to save pageant registration. Please try again.");
        }
    }

    async savePageantRegistrationResultsVisibility(pageantRegistrationId: number, isResultsPrivate: boolean): Promise<boolean> {
        try {
            // Check if the pageantRegistrationId is valid
            if (!pageantRegistrationId || pageantRegistrationId <= 0) {
                throw new Error("Invalid pageant registration ID.");
            }

            // Check if isResultsPrivate is a boolean
            if (typeof isResultsPrivate !== 'boolean') {
                throw new Error("Invalid value for isResultsPrivate.");
            }

            // Call the API method to save results visibility
            const response = await this.PageantRegistration.patchPageantRegistrationResultsVisibility({ pageantRegistrationId, isResultsPrivate });

            // Check the response and return a boolean based on success
            return response && response.data !== null;
        } catch (error) {
            console.error("Error while saving pageant registration:", error);
            throw new Error("Failed to save pageant registration. Please try again.");
        }
    }


    async savePageantRegistrationHeadShot(pageantRegistrationId: number, headShot: PictureModel): Promise<boolean> {
        try {
            // Check if the pageantRegistrationId is valid
            if (!pageantRegistrationId || pageantRegistrationId <= 0) {
                throw new Error("Invalid pageant registration ID.");
            }

            // Check if headShot is a valid PictureModel (optional, depending on your validation requirements)
            if (!headShot || typeof headShot !== 'object' || !headShot.image) {
                throw new Error("Invalid headShot object.");
            }

            // Call the API method to save headshot
            const response = await this.PageantRegistration.patchPageantRegistrationHeadShot(
                headShot,
                { pageantRegistrationId }
            );

            // Check the response and return a boolean based on success
            return response && response.data !== null;
        } catch (error) {
            console.error("Error while saving pageant registration:", error);
            throw new Error("Failed to save pageant registration. Please try again.");
        }
    }

    async deletePageantRegistration(id: number): Promise<boolean> {
        try {
            var pageantRegistrationResult = await this.PageantRegistration.deletePageantRegistration(id);
            if (pageantRegistrationResult.data.data) {
                return true;
            }
            return false;
        }
        catch (error) {
            console.log("Error: " + JSON.stringify(error));
            return false;
        }
    }

    // Simplified method to load scores for a contestant
    async loadScores(registeredPageantContestantId: number): Promise<void> {
        try {
            // Directly calling the service and assuming it returns an array of scores
            const scores = await this.ReceivedScoreService.getByContestantDetail(registeredPageantContestantId);

            if (Array.isArray(scores) && scores.length > 0) {
                this.ReceivedScore = scores; // Assign the scores to your store
            } else {
                console.warn('No scores found for this contestant.');
            }
        } catch (error) {
            console.error('Error loading scores:', error);
        }
    }

    // Method to save scores for a pageant
    async saveScores(pageantId: number, scores: ReceivedScoreBaseModel[]): Promise<boolean> {
        try {
            // Iterate through each score and save it individually
            for (const score of scores) {
                // Wrap the score in an array and call the saveScoreCreate method
                const result = await this.ReceivedScoreService.saveScoreCreate([score]);
                if (!result) {
                    console.warn('Failed to save score for:', score);
                    return false; // Exit early if any score fails to save
                }
            }

            // If all scores saved successfully, reload the scores for the first contestant
            if (scores.length > 0 && scores[0].registeredPageantContestantId) {
                console.log('Scores saved successfully.');
                await this.loadScores(scores[0].registeredPageantContestantId);
            } else {
                console.warn('No contestant ID available to reload scores.');
            }

            return true; // All scores saved successfully
        } catch (error) {
            console.error('Error saving scores:', error);
            return false;
        }
    }

// In PageantRegistrationStore
async bulkSaveScores(pageantId: number, scores: ReceivedScoreBaseModel[]): Promise<boolean> {
    try {
      // Call your controller method that saves the scores
      const result = await this.ReceivedScoreService.bulkSaveCreate(pageantId, scores);
      return result ? true : false;
    } catch (error) {
      console.error('Error in bulk saving scores:', error);
      return false;
    }
  }
  
    // Inside PageantRegistrationStore
// Inside PageantRegistrationStore
// async getScoresForContestant(registeredContestantId: number): Promise<ReceivedScoreBaseModel[]> {
//     try {
//         // Call the service method to fetch scores
//         const response = await this.ReceivedScoreService.getByContestantDetail(registeredContestantId);

//         // Safeguard against unexpected formats
//         if (!Array.isArray(response)) {
//             console.error('Unexpected response format:', response);
//             return [];
//         }

//         // Return the fetched scores
//         return response;
//     } catch (error) {
//         console.error('Error fetching scores:', error);
//         return []; // Return an empty array in case of error
//     }
// }

// Let's assume you have a generated or custom API client for calling the back-end method
async getByContestantDetail(registeredContestantId: number): Promise<ReceivedScoreBaseModel[]> {
    try {
        // Assuming this calls the backend API and returns a response object
        const response = await this.ReceivedScoreService.getByContestantDetail(registeredContestantId);
        
        // Check if the response is successful
        if (!response.ok) {
            throw new Error(`Error fetching scores: ${response.statusText}`);
        }

        // Parse the response body as JSON
        const data = await response.json();

        // Return the data if it's an array, otherwise return an empty array
        return Array.isArray(data) ? data : [];
    } catch (error) {
        console.error("Error fetching scores from API:", error);
        return [];  // Return an empty array on error
    }
}

async fetchScores(registeredContestantId: number) {
    try {
        // Call the service method to fetch scores from the back-end API
        const scores = await this.getByContestantDetail(registeredContestantId);

        // Log the scores to confirm
        console.log('Scores received in component/store:', scores);

        // Do something with the scores, e.g., save them in the state or pass them to a component
        return scores;
    } catch (error) {
        console.error('Error in fetching scores:', error);
        return [];
    }
}



async getTotalScoresWithDropsForContestantAsyncAll(registeredContestantId: number): Promise<{ [key: string]: any }> {
    try {
        // Use optional chaining to safely access the method
        const scores = await this.ReceivedScoreService?.getTotalScoresWithDropsForContestantByAocAllDetail(registeredContestantId);

        // Handle case if the service or response is undefined
        if (!scores) {
            console.error('No scores returned from the service');
            return {}; // Return an empty object if no scores
        }

        console.log('Total scores received:', scores);
        return scores; // Return the fetched scores
    } catch (error) {
        console.error('Error fetching total scores:', error);
        return {}; // Return an empty object in case of error
    }
}








    async showRegistrationForm() {
        this.ShowRegistrationForm = true;
    }

    async hideRegistrationForm() {
        this.ShowRegistrationForm = false;
    }

    async setAllowEdit(allowEdit: boolean) {
        this.AllowEdit = allowEdit;
    }

    async getRegistration(id): Promise<RegisteredPageantContestantModel | undefined> {
        try {
            var registrationResult = await this.PageantRegistration.getPageantRegistrationById(id);
            return registrationResult?.data?.data;
        }
        catch (error) {
            console.log("Errror: " + JSON.stringify(error))
        }
    }


    async refreshCurrentRegistration() {
        if (this.CurrentPageantRegistration.id) {
            var result = await this.getRegistration(this.CurrentPageantRegistration.id);
            if (result) {
                this.CurrentPageantRegistration = result;
            }
        }
    }

}

let pageantRegistrationStore: PageantRegistrationStore;

export function GetPageantRegistrationStore(): PageantRegistrationStore {
    if (!pageantRegistrationStore) {
        pageantRegistrationStore = new PageantRegistrationStore({}, false);
    }
    return pageantRegistrationStore;
}