import { LAYOUT, MENU_BEHAVIOUR, NAV_COLOR, MENU_PLACEMENT, RADIUS, THEME_COLOR, USER_ROLE, USER_TYPE, USER_RELATIONSHIP, NAV_USER, EVENT_TYPE } from 'constants.js';

export const IS_DEMO = true;
export const IS_AUTH_GUARD_ACTIVE = true;
export const SERVICE_URL = '/app';
export const USE_MULTI_LANGUAGE = true;

// For detailed information: https://github.com/nfl/react-helmet#reference-guide
export const REACT_HELMET_PROPS = {
  defaultTitle: 'HelloTiara ',
  titleTemplate: '%s | Hello Tiara',
  meta: [
    { name: 'description', content: 'The all-in-one, ultimate pageant management software for the glitz, natural, and scholarship industries created for all roles in pageantry.' },
    { property: 'og:title', content: 'HelloTiara' },
    { property: 'og:description', content: 'The all-in-one, ultimate pageant management software for the glitz, natural, and scholarship industries created for all roles in pageantry.' },
    { property: 'og:image', content: 'https://hellotiaraphotostorage.blob.core.windows.net/hello-tiara/HelloTiara-FindPageants.png' },
    // Add more OG properties as needed
  ],
};

export const DEFAULT_PATHS = {
  APP: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_FORM: '/reset-password-form',
  GET_STARTED: '/get-started',
  PRICING: '/pricing',
  CONTACT: '/contact',
  COMINGSOON: '/coming-soon',
  USER_WELCOME: '/dashboards/default',
  NOTFOUND: '/page-not-found',
  UNAUTHORIZED: '/unauthorized',
  INVALID_ACCESS: '/invalid-access',
  HOME: '/home',
};

export const DEFAULT_SETTINGS = {
  MENU_PLACEMENT: MENU_PLACEMENT.Horizontal,
  MENU_BEHAVIOUR: MENU_BEHAVIOUR.Pinned,
  LAYOUT: LAYOUT.Fluid,
  RADIUS: RADIUS.Standard,
  COLOR: THEME_COLOR.LightHelloTiara,
  NAV_COLOR: NAV_COLOR.Match,
  NAV_USER: NAV_USER.Business,
  USE_SIDEBAR: true,
};

export const DEFAULT_USER = {
  id: 1,
  name: 'Brigette Havard',
  thumb: '/img/profile/Brigette-headshot-bw.jpg',
  role: USER_ROLE.Admin,
  type: USER_TYPE.Viewable,
  city: 'Kansas City',
  state: 'MO',
  business: true,
  contestant: true,
  email: 'brigette@hellotiara.com',
};

export const DEFAULT_VENDOR = {
  id: 1,
  name: 'Brigette Havard',
  thumb: '/img/profile/Brigette-headshot-bw.jpg',
  service: 'Hair and Makeup',
  role: USER_ROLE.User,
  type: USER_TYPE.Vendor,
  city: 'City',
  state: 'STATE',
  business: true,
  contestant: false,
  email: 'missy@misssunshine.com',
};

export const DEFAULT_CONTESTANT = {
  id: 2,
  name: 'Phoebe Wilder',
  thumb: '/img/pageants/Phoebe.jpg',
  birthday: '11/14/xxxx',
  city: 'Kansas City',
  state: 'MO',
  eye: 'Brown',
  hair: 'Brown',
  role: USER_ROLE.User,
  type: USER_TYPE.Contestant,
  business: false,
  contestant: true,
  stageshot: '/img/pageants/PhoebeStageShot.jpg',
  title: 'Ultimate Grand Supreme'
};

export const DEFAULT_PAGEANT = {
  id: 1,
  name: 'Little Miss Sunshine',
  system: 'The Best System',
  date: '01/01/2024',
  fallbackdate: '07/01/2023',
  location: 'Nashville, TN',
  venue: 'Holiday Inn North',
  type: 'National',
  style: 'Glitz',
  depositprice: '$150',
  basicpackageprice: '$350',
  megapackageprice: '$795',
  earlybirddiscountdate: '03/01/2023',
  thumb: '/img/profile/Brigette-headshot-bw.jpg',
  slogan: 'The Pageant of the Century!  $2000 winner!',
};

export const AFF = {
  id: 1,
  name: "Nationals 2023 - Grand Finale",
  system: 'Americas Fabulous Faces',
  date: 'June 23-25, 2023',
  fallbackdate: '01/01/2023',
  location: 'Lexington, KY',
  venue: 'Clarion Hotel Conference Center',
  type: 'National',
  style: 'Glitz',
  depositprice: '$150',
  registrationfee: '$195',
  supremepackageprice: '$475',
  megapackageprice: '$795',
  earlybirddiscountdate: '03/01/2023',
  thumb: '/img/pageants/AFF-banner-square.png',
  slogan: 'Dont miss our last AFF! FOUR $2000 cash winner!',
};

export const DEFAULT_BUSINESS = {
  id: 1,
  name: 'B.K.Designs',
  thumb: '/img/logo/bkdesign-logo.jpg',
  type: 'Vendor',
  owner: DEFAULT_VENDOR.name,
  logo: '/img/favicon/favicon-196x196.png',
  business: true,
};

export const VENDOR_CATEGORY = {
  BeautyStyle: 'Beauty/Styling',
  CustomWardrobe: 'Custom Wardrobe',
  Photography: 'Photography',
  CrownsSashesAwards: 'Crowns/Sashes/Awards',
  PageantSwag: 'Pageant Swag',
  GraphicsDigitalPrint: 'Graphics/Digital/Print',
  CoachingConsultingPrep: 'Coach/Consultant/Prep',
  GiftsGoodies: 'Gifts/Goodies',
  CustomMusicPropsExtras: 'Custom Music/Props/Extras',
  ProductionEmcee: 'Production Crew/Emcee',
  Judge: 'Judge',
};


export const REDUX_PERSIST_KEY = 'Hello Tiara';
