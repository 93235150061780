// import redux and persist plugins
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import persistStore from 'reduxjs-toolkit-persist/es/persistStore';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'reduxjs-toolkit-persist/es/constants';

// import theme reducers
import settingsReducer from 'settings/settingsSlice';
import layoutReducer from 'layout/layoutSlice';
import langReducer from 'lang/langSlice';
import authReducer from 'auth/authSlice';
import menuReducer from 'layout/nav/main-menu/menuSlice';
import notificationReducer from 'layout/nav/notifications/notificationSlice';
import scrollspyReducer from 'components/scrollspy/scrollspySlice';
import chatReducer from 'views/apps/chat/chatSlice';
import calendarReducer from 'views/apps/calendar/calendarSlice';
import contactsReducer from 'views/apps/contacts/contactsSlice';
import tasksReducer from 'views/apps/tasks/tasksSlice';
// import eventAwardsReducer from 'views/events/awards/eventAwardsSlice';
import noteReducer from 'views/events/chat/noteSlice';
// import entrantsReducer from 'views/events/entrants/entrantsSlice';
import staffReducer from 'views/events/staff/staffSlice';

// import persist key
import { REDUX_PERSIST_KEY } from 'config.js';

const persistConfig = {
  key: REDUX_PERSIST_KEY,
  storage,
  // whitelist: ['menu', 'settings', 'lang'],
  whitelist: ['', 'settings', ''],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    settings: settingsReducer,
    layout: layoutReducer,
   lang: langReducer,
    auth: authReducer,
    menu: menuReducer,
  //  notification: notificationReducer,
    scrollspy: scrollspyReducer,
   // chat: chatReducer,
   // calendar: calendarReducer,
   // contacts: contactsReducer,
   // tasks: tasksReducer,
    // awards: eventAwardsReducer,
   // note: noteReducer,
    // entrants: entrantsReducer,
   // staff: staffReducer,
  })
);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
const persistedStore = persistStore(store);
export { store, persistedStore };
