/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { EventModel, EventPersonnelModel } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class EventPersonnel<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags EventPersonnel
   * @name GetJudgesByEventDetail
   * @request GET:/EventPersonnel/GetJudgesByEvent/{eventId}
   */
  getJudgesByEventDetail = (eventId: number, params: RequestParams = {}) =>
    this.request<EventPersonnelModel[], any>({
      path: `/EventPersonnel/GetJudgesByEvent/${eventId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EventPersonnel
   * @name AddJudgeToEventCreate
   * @request POST:/EventPersonnel/AddJudgeToEvent
   */
  addJudgeToEventCreate = (data: EventPersonnelModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/EventPersonnel/AddJudgeToEvent`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags EventPersonnel
   * @name RemoveJudgeFromEventDelete
   * @request DELETE:/EventPersonnel/RemoveJudgeFromEvent/{id}
   */
  removeJudgeFromEventDelete = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/EventPersonnel/RemoveJudgeFromEvent/${id}`,
      method: 'DELETE',
      ...params,
    });
  /**
   * No description
   *
   * @tags EventPersonnel
   * @name GetEventsForJudgeDetail
   * @request GET:/EventPersonnel/GetEventsForJudge/{userId}
   */
  getEventsForJudgeDetail = (userId: number, params: RequestParams = {}) =>
    this.request<EventModel[], any>({
      path: `/EventPersonnel/GetEventsForJudge/${userId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
}
