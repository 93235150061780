/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BooleanResponseWrapper,
  CustomBooleanResponseWrapper,
  ForgotPasswordModel,
  ForgotPasswordPhoneModel,
  LoginModel,
  LoginResponse,
  RegisterModel,
  ResetModel,
  ResetPasswordWithTokenModel,
  UserModelResponseWrapper,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Account<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Account
   * @name LoginCreate
   * @request POST:/Account/Login
   */
  loginCreate = (data: LoginModel, params: RequestParams = {}) =>
    this.request<LoginResponse, any>({
      path: `/Account/Login`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name CurrentUserList
   * @request GET:/Account/CurrentUser
   */
  currentUserList = (params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/Account/CurrentUser`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name RegisterCreate
   * @request POST:/Account/Register
   */
  registerCreate = (data: RegisterModel, params: RequestParams = {}) =>
    this.request<UserModelResponseWrapper, any>({
      path: `/Account/Register`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name LogoutCreate
   * @request POST:/Account/Logout
   */
  logoutCreate = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/Account/Logout`,
      method: 'POST',
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name DeleteCreate
   * @request POST:/Account/Delete
   */
  deleteCreate = (
    query?: {
      /** @format int32 */
      userId?: number;
      /** @format int32 */
      requestingUserId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<BooleanResponseWrapper, any>({
      path: `/Account/Delete`,
      method: 'POST',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name ResetPasswordCreate
   * @request POST:/Account/ResetPassword
   */
  resetPasswordCreate = (data: ResetModel, params: RequestParams = {}) =>
    this.request<BooleanResponseWrapper, any>({
      path: `/Account/ResetPassword`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name ForgotPasswordCreate
   * @request POST:/Account/ForgotPassword
   */
  forgotPasswordCreate = (data: ForgotPasswordModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/Account/ForgotPassword`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name ForgotPasswordPhoneCreate
   * @request POST:/Account/ForgotPasswordPhone
   */
  forgotPasswordPhoneCreate = (data: ForgotPasswordPhoneModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/Account/ForgotPasswordPhone`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Account
   * @name ResetPasswordWithTokenCreate
   * @request POST:/Account/ResetPasswordWithToken
   */
  resetPasswordWithTokenCreate = (data: ResetPasswordWithTokenModel, params: RequestParams = {}) =>
    this.request<CustomBooleanResponseWrapper, any>({
      path: `/Account/ResetPasswordWithToken`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
