import { makeObservable, observable, action, computed, runInAction } from "mobx"
import { EventBaseModel, EventModel, EventPersonnelModel } from "API/Generated/data-contracts"
import { Event } from "API/Generated/Event"
import { makePersistable } from "mobx-persist-store";
import { RequestStatus } from "SessionState/RequestHandling/RequestStatus";
import { compressData, decompressData } from 'utilities/compression'; // Adjust the path as needed
import { EventPersonnel } from "API/Generated/EventPersonnel";



interface Request {
  id: number;
  status: RequestStatus;
}

interface EventResponse {
  data?: {
    data?: EventModel[] | null;
  };
}

class EventStore {
  EventService: Event = new Event();
  EventPersonnelService: EventPersonnel = new EventPersonnel();
  CurrentEvent: EventModel;
  DirectorEventList: EventModel[];
  FutureEventList: EventModel[];
  PastEventList: EventModel[];
  FutureEventListBasicInfo: EventBaseModel[];
  totalFutureEventsCount: number = 0;
 
  CurrentEventRequest: Request = {
    id: 0,
    status: RequestStatus.PENDING
  };

  FutureEventsListRequest: Request = {
    id: 0,
    status: RequestStatus.PENDING
  };

  PastEventsListRequest: Request = {
    id: 0,
    status: RequestStatus.PENDING
  };

  constructor(currentEvent: EventModel, directorEventList: EventModel[], futureEventList: EventModel[], pastEventList: EventModel[], futureEventsListBasicInfo: EventBaseModel[]) {
    makeObservable(this, {
      CurrentEvent: observable,
      DirectorEventList: observable,
      FutureEventList: observable,
      FutureEventListBasicInfo: observable,
      PastEventList: observable,
      getEvent: action,
      saveEvent: action,
      getEventByDirector: action,
      getFutureEvents: action,
      getFutureEventsSearchFilter: action,
      fetchTotalFutureEventsCount: action,
      getPastEvents: action,
      getAllEvents: action,
      resetCurrentEvent: action,
      addJudgeToEvent: action,
      getJudgesByEventId: action,
    })
    // makePersistable(this, { name: 'EventStore', properties: ['CurrentEvent', 'DirectorEventList', 'FutureEventList', 'PastEventList', 'FutureEventListBasicInfo'] });
    this.CurrentEvent = currentEvent;
    this.DirectorEventList = directorEventList;
    this.FutureEventList = futureEventList;
    this.FutureEventListBasicInfo = futureEventsListBasicInfo;
    this.PastEventList = pastEventList;
  }

  async addJudgeToEvent(eventPersonnel: EventPersonnelModel) {
    try {
      await this.EventPersonnelService.addJudgeToEventCreate(eventPersonnel);
      runInAction(() => {
        this.CurrentEvent?.personnel?.push(eventPersonnel); // Update the local state
      });
    } catch (error) {
      console.error("Error adding judge to event:", error);
    }
  }

  async getJudgesByEventId(eventId: number): Promise<EventPersonnelModel[]> {
    try {
      const result = await this.EventPersonnelService.getJudgesByEventDetail(eventId);
      return result?.data || [];
    } catch (error) {
      console.error("Error fetching judges by event ID:", error);
      return [];
    }
  }
  


  storeEventsInSessionStorage(events: EventModel[]) {
    const compressedEvents = compressData(events);
    sessionStorage.setItem('upcomingEvents', compressedEvents);
  }

  getEventsFromSessionStorage(): EventModel[] {
    const compressedEvents = sessionStorage.getItem('upcomingEvents');
    return compressedEvents ? decompressData(compressedEvents) : [];
  }

  async getEvent(id: number) {
    try {
      var eventResult = await this.EventService.getEventById(id)
      if (eventResult.data.data) {
        runInAction(() => {
          this.CurrentEvent = eventResult?.data.data!;
        });
      }
    } catch (error) {
      console.log("Error:" + JSON.stringify(error));
    }
  }

  async saveEvent(model: EventBaseModel) {
    try {
      var eventResult = await this.EventService.addEvent(model);
      if (eventResult.data.data) {
        runInAction(() => {
          this.CurrentEvent = eventResult?.data.data!;
        });
      }
    } catch (error) {
      console.log("Error: " + JSON.stringify(error));
    }
  }

  async getEventByDirector(id: number) {
    try {
      var eventResult = await this.EventService.getEventByDirectorId(id)
      // console.log("EventResult", eventResult);

      if (eventResult.data.data) {
        runInAction(() => {
          this.DirectorEventList = eventResult.data.data!;
        });
      }
    } catch (error) {
      console.log("Error: " + JSON.stringify(error));
    }
  }

  // async getFutureEvents() {
  //     try {
  //         var eventResult = await this.EventService.getAllFutureEvents()
  //         if (eventResult.data.data) {
  //             runInAction(() => {
  //                 this.FutureEventList = eventResult.data.data!;
  //               });
  //         }
  //     }
  //     catch (error) {
  //         console.log("Error: " + JSON.stringify(error));
  //     }
  // }

  async getFutureEvents(reset: boolean = false) {
    try {
      if (this.FutureEventsListRequest.status == RequestStatus.ACTIVE || this.FutureEventsListRequest.status == RequestStatus.COMPLETED
        && !reset) {
        return;
      }
      this.FutureEventsListRequest = { id: 0, status: RequestStatus.ACTIVE };
      var futureEventsListResult = await this.EventService.getAllFutureEvents()
      // console.log("data", pageantResult);
      if (futureEventsListResult.data.data) {
        this.FutureEventsListRequest.status = RequestStatus.COMPLETED;
        this.FutureEventList = futureEventsListResult?.data.data;
        this.storeEventsInSessionStorage(this.FutureEventList);
      }
    } catch (error) {
      this.FutureEventsListRequest.status = RequestStatus.ERROR;
      console.log("Error:" + JSON.stringify(error));
    }
  }

  async getFutureFeaturedEvents(reset: boolean = false) {
    try {
      if (this.FutureEventsListRequest.status == RequestStatus.ACTIVE || this.FutureEventsListRequest.status == RequestStatus.COMPLETED
        && !reset) {
        return;
      }
      this.FutureEventsListRequest = { id: 0, status: RequestStatus.ACTIVE };
      var futureEventsListResult = await this.EventService.getAllFutureEvents()
      // console.log("data", pageantResult);
      if (futureEventsListResult.data.data) {
        this.FutureEventsListRequest.status = RequestStatus.COMPLETED;
        this.FutureEventList = futureEventsListResult?.data.data;
      }
    } catch (error) {
      this.FutureEventsListRequest.status = RequestStatus.ERROR;
      console.log("Error:" + JSON.stringify(error));
    }
  }

  async getFutureEventsSearchFilter(page: number = 1, pageSize: number = 10, reset: boolean = false): Promise<EventModel[]> {
    try {
      if (this.FutureEventsListRequest.status === RequestStatus.ACTIVE || (this.FutureEventsListRequest.status === RequestStatus.COMPLETED && !reset && page === 1)) {
        return this.FutureEventList;
      }

      if (reset) {
        this.FutureEventList = []; // Clear the current event list if resetting
      }

      this.FutureEventsListRequest = { id: 0, status: RequestStatus.ACTIVE };
      const futureEventsListResult = await this.EventService.getAllFutureEventsSearchFilter({ page, pageSize });

      if (futureEventsListResult.data && futureEventsListResult.data.data) {
        runInAction(() => {
          this.FutureEventsListRequest.status = RequestStatus.COMPLETED;
          if (reset) {
            this.FutureEventList = futureEventsListResult.data.data!;
          } else {
            this.FutureEventList = [
              ...this.FutureEventList,
              ...futureEventsListResult.data.data!,
            ];
          }
          this.storeEventsInSessionStorage(this.FutureEventList);
        });

        return this.FutureEventList;
      }

      return [];
    } catch (error) {
      runInAction(() => {
        this.FutureEventsListRequest.status = RequestStatus.ERROR;
      });
      console.log("Error:" + JSON.stringify(error));
      return [];
    }
  }

  async fetchTotalFutureEventsCount(): Promise<number> {
    try {
      const response = await fetch('/Event/GetTotalFutureEventsCount');
      const totalCount = await response.json();
      runInAction(() => {
        this.totalFutureEventsCount = totalCount;
      });
      return totalCount;
    } catch (error) {
      console.error('Error fetching total events count:', error);
      return 0;
    }
  }





  // async getFutureEventsBasicInfo() {
  //     try {
  //         var eventResult = await this.EventService.getAllFutureEventsBasicInfo()
  //         if (eventResult.data.data) {
  //             runInAction(() => {
  //                 this.FutureEventList = eventResult.data.data!;
  //               });
  //         }
  //     }
  //     catch (error) {
  //         console.log("Error: " + JSON.stringify(error));
  //     }
  // }

  // async getPastEvents() {
  //     try {
  //         var eventResult = await this.EventService.getAllPastEvents()
  //         if (eventResult.data.data) {
  //             runInAction(() => {
  //                 this.PastEventList = eventResult.data.data!;
  //               });
  //         }
  //     }
  //     catch (error) {
  //         console.log("Error: " + JSON.stringify(error));
  //     }
  // }

  async getPastEvents(reset: boolean = false) {
    try {
      if (this.PastEventsListRequest.status == RequestStatus.ACTIVE || this.PastEventsListRequest.status == RequestStatus.COMPLETED
        && !reset) {
        return;
      }
      this.PastEventsListRequest = { id: 0, status: RequestStatus.ACTIVE };
      var pastEventsListResult = await this.EventService.getAllPastEvents()
      // console.log("data", pageantResult);
      if (pastEventsListResult.data.data) {
        this.PastEventsListRequest.status = RequestStatus.COMPLETED;
        this.PastEventList = pastEventsListResult?.data.data;
      }
    } catch (error) {
      this.PastEventsListRequest.status = RequestStatus.ERROR;
      console.log("Error:" + JSON.stringify(error));
    }
  }

  async getAllEvents() {
    try {
      var eventResult = await this.EventService.getAllEvents()
      if (eventResult.data.data) {
        runInAction(() => {
          this.FutureEventList = eventResult.data.data!;
        });
      }
    }
    catch (error) {
      console.log("Error: " + JSON.stringify(error));
    }

  }

  async updateDirector(directorId: number, eventId: number) {
    try {
      var eventResult = await this.EventService.assignDirectorUserIdToEventId(eventId, directorId)
      console.log("Updating Director", eventResult, directorId, eventId);
      if (eventResult.data.data) {
        return true;
      }
    }
    catch (error) {
      console.log("Error: " + JSON.stringify(error));
      return false
    }

  }

  async getAllEventsForUser(userId: number): Promise<EventModel[]> {
    try {
        const result = await this.EventPersonnelService.getEventsForJudgeDetail(userId); // Fetch events for the user
        if (result?.data) {
            return result.data; // Assuming this is an array of EventModel
        } else {
            return [];
        }
    } catch (error) {
        console.error("Error fetching events for user:", error);
        return [];
    }
}

async getJudgeEvents(userId: number): Promise<EventModel[]> {
  try {
      const events = await this.EventPersonnelService.getEventsForJudgeDetail(userId);
      return events.data;
  } catch (error) {
      console.error("Error fetching judge events:", error);
      return [];
  }
}

  async resetCurrentEvent() {
    this.CurrentEvent = {};
  }
}

let eventStore: EventStore;

export function GetEventStore() {
  if (!eventStore) {
    eventStore = new EventStore({}, [], [], [], []);
  }
  return eventStore;
}