/* eslint-disable */
import { lazy } from 'react';
import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';
import PageantKnowledgeBase from 'views/resources/PageantKnowledgeBase';
import { UserRole } from 'helpers/UserRole';
import { GetEventStore } from 'SessionState/Event/EventStore';
import { GetUserStore } from 'SessionState/User/UserStore';
import { observer } from 'mobx-react';
import { User } from 'oidc-client';
import { BetaWrapper } from 'views/default/BetaWrapper';
import id from 'date-fns/locale/id/index';
import { GetContestantStore } from 'SessionState/Contestant/ContestantStore';


const eventStore = GetEventStore();
const userStore = GetUserStore();
const contestantStore = GetContestantStore();




const dashboard = lazy(() => import('views/dashboard/Dashboard'));
const admindashboard = lazy(() => import('views/admin/AdminDashboard'));
const home = lazy(() => import('views/home/Home'));
const newsfeed = lazy(() => import('views/feed/NewsFeed'));
const learnmore = lazy(() => import('views/home/components/LandingPage'));
const users = lazy(() => import('views/admin/users/UsersList'));
const admin = {
  approvals: lazy(() => import('views/admin/AdminApprovals')),
  contestantusers: lazy(() => import('views/admin/users/ContestantUsersList')),
  directorusers: lazy(() => import('views/admin/users/DirectorUsersList')),
  allusers: lazy(() => import('views/admin/users/UsersList')),
  allbusinesses: lazy(() => import('views/admin/businesses/HTBusinessesList')),
  vendorcategories: lazy(() => import('views/admin/VendorCategories')),
  events: lazy(() => import('views/admin/events/HTEventsList')),
};

const shipping = lazy(() => import('views/template-items/shipping/Shipping'));
const discount = lazy(() => import('views/discount/Discount'));
const contacts = lazy(() => import('views/apps/contacts/Contacts'));
const profile = lazy(() => import('views/users/UserProfile'));
const chat = lazy(() => import('views/apps/chat/Chat'));
const favorites = lazy(() => import('views/favorites/Favorites'));
const calendar2 = lazy(() => import('views/apps/calendar/Calendar'));
const calendar = lazy(() => import('views/events/calendar/Calendar'));
const tasks = lazy(() => import('views/tasks/Tasks'));
const search = lazy(() => import('views/search/Search'));
const marketplace = lazy(() => import('views/template-items/marketplace/Marketplace'));
const orders = lazy(() => import('views/orders/list/OrdersList'));
const aboutus = lazy(() => import('views/aboutus/AboutUs'));
const termsandconditions = lazy(() => import('views/default/TermsAndConditions'));
const privacypolicy = lazy(() => import('views/default/PrivacyPolicy'));
const datadeletioninstructions = lazy(() => import('views/default/DataDeletionInstructions'));
const spinner = lazy(() => import('views/interface/components/Spinners'));
// const search = {
//   home: lazy(() => import('views/search/Search')),
// };


const events = {
  add: lazy(() => import('views/events/add/AddEditEvent')),
  entrants: lazy(() => import('views/events/entrants/Entrants')),
  manage: lazy(() => import('views/events/manage/EventManagement')),
  detail: lazy(() => import('views/events/detail/EventDetails')),
  // list: lazy(() => import('views/events/list/EventList')),
  list: lazy(() => import('views/dashboard/components/MyEvents')),
  upcoming: lazy(() => import('views/events/Events')),
  results: lazy(() => import('views/events/results/PastEventResultDetails')),
  past: lazy(() => import('views/events/results/PastEvents')),
  sample: lazy(() => import('views/home/components/SampleEventDetails')),
  registrationdashboard: lazy(() => import('views/events/entrants/RegistrationDashboard')),
  scoringhome: lazy(() => import('views/events/scoring/ScoreHome')),
};
const membership = {
  home: lazy(() => import('views/membership/Membership')),
  pricing: lazy(() => import('views/membership/pricing/Pricing')),
  select: lazy(() => import('views/membership/pricing/SubscriptionTable')),
  buildProfile: lazy(() => import('views/users/components/BuildYourProfile')),
};

const coach = {
  home: lazy(() => import('views/users/coach/Coach')),
};
const members = {
  home: lazy(() => import('views/users/Members')),
  profile: lazy(() => import('views/users/MemberProfile')),
};

{userStore.CurrentUser.contestantUserAccounts?.map(contestant => {
  return (
    contestant.firstName
  )
})}

const contestant = {
  profile: lazy(() => import('views/users/contestants/ContestantProfile')),
  eventmanage: lazy(() => import('views/users/contestants/eventManagement/EntrantEventManagement')),
  inpersonregistration: lazy(() => import('views/events/entrants/registration/EntrantInPersonView')),
};


const contestants = {
  home: lazy(() => import('views/users/contestants/ContestantMembers')),
  users: lazy(() => import('views/users/contestants/MyContestants')),
  add: lazy(() => import('views/users/contestants/AddNewContestant')),
  publicprofile: lazy(() => import('views/users/contestants/ContestantPublicProfile')),
};

const vendor = {
  overview: lazy(() => import('views/users/vendor/Vendor')),
  events: lazy(() => import('views/events/list/EventList')),
  orders: lazy(() => import('views/users/vendor/orders/Orders')),
  invoice: lazy(() => import('views/users/vendor/orders/Orders')),
  portfolio: lazy(() => import('views/users/vendor/portfolio/Portfolio')),
};

const productsservices = {
  detail: lazy(() => import('views/productsservices/detail/ProductServiceDetails')),
  list: lazy(() => import('views/productsservices/list/ProductServiceList')),
};
const order = {
  overview: lazy(() => import('views/users/vendor/orders/Orders')),
  details: lazy(() => import('views/users/vendor/orders/detail/OrderDetails')),
};
const business = {
  details: lazy(() => import('views/business/detail/BusinessDetails')),
  home: lazy(() => import('views/business/Businesses')),
};

const resources = {
  dashboard: lazy(() => import('views/resources/ResourcesDashboard')),
  hellotiarafaq: lazy(() => import('views/resources/HelloTiaraFaq')),
  pageantknowledgebase: lazy(() => import('views/resources/PageantKnowledgeBase')),
  general: lazy(() => import('views/settings/general/General')),
  dictionary: lazy(() => import('views/resources/dictionary/Dictionary2')),
};

const guides = {
  guide01: lazy(() => import('views/resources/guides/glitz/Guide_01_AllAboutGlitz')),
};

const settings = {
  home: lazy(() => import('views/settings/home/Home')),
  general: lazy(() => import('views/settings/general/General')),
  account: lazy(() => import('views/settings/user/AccountSettings')),
  contestant: lazy(() => import('views/settings/contestant/ContestantAccountSettings')),
  business: lazy(() => import('views/settings/business/BusinessAccountSettings')),
};

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;




const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/home`,
    },
    {
      path: `${appRoot}/dashboard`,
      component: dashboard,
      label: 'Dashboard',
      icon: 'grid-5',
      roles: [UserRole.Director, UserRole.Vendor, UserRole.Admin, UserRole.Coach],
    },
    {
      path: `${appRoot}/profile`,
      component: profile,
      label: 'My Profile',
      icon: 'user',
      roles: [UserRole.User]
    },
    // {
    //   path: `${appRoot}/contestant-view`, // Add this route for contestant view
    //   component: contestant.inpersonregistration,
    //   label: 'Contestant View',
    //   icon: 'user',
    //   roles: [UserRole.Admin],
    // },
    // {
    //   path: `${appRoot}/members`,
    //   // component: contestants.home,
    //   label: 'Members',
    //   icon: 'user',
    //   subs: [
    //     { path: '/directors', label: 'Directors', component: members.home },
    //   ],
    //   roles: [UserRole.Admin]
    // },
    {
      path: `${appRoot}/my-contestants`,
      component: contestants.users,
      label: 'My Contestants',
      icon: 'user',
      // subs: [
      //   { path: '/my-contestants', label: 'My Contestants', component: contestants.home, roles: [UserRole.Admin, UserRole.Contestant] },
      //   // { path: '/add', label: 'Add New Contestant', component: contestants.add },
      // ],
   
      roles: [UserRole.Admin, UserRole.Contestant]
    },
    {
      path: `${appRoot}/search`,
      component: search,
      icon: 'search',
      label: 'Explore',
      // roles: [UserRole.User]
    },
    {
      path: `${appRoot}/favorites`,
      component: favorites,
      label: 'Favorites',
      icon: 'heart',
      roles: [UserRole.Admin]
    },

    // {
    //   path: `${appRoot}/chat`,
    //   component: chat,
    //   label: 'Chat',
    //   icon: 'messages',
    // },

  ],
  unauthorizedMenuItems: [
    // {
    //   path: `${appRoot}/learn-more`,
    //   icon: 'info',
    //   component: learnmore,
    //   label: 'Learn More',
    // },
    {
      path: `${appRoot}/membership/pricing`,
      icon: 'tag',
      component: membership.pricing,
      label: 'Pricing',
    },

    {
      path: `${appRoot}/resources/hello-tiara-faq`,
      icon: 'question-hexagon',
      component: resources.hellotiarafaq,
      label: 'FAQ',
    },
    {
      path: DEFAULT_PATHS.CONTACT,
      exact: true,
      redirect: true,
      icon: 'send',
      to: `${appRoot}/contact`,
      label: 'Contact Us',
    },
    // {
    //   path: DEFAULT_PATHS.RESET_PASSWORD,
    //   exact: true,
    //   redirect: true,
    //   icon: 'send',
    //   to: `${appRoot}/reset-password`,
    //   label: 'Reset Password',
    // },
    {
      path: `${appRoot}/search`,
      component: search,
      icon: 'search',
      label: 'Explore',
    },
    {
      path: DEFAULT_PATHS.REGISTER,
      exact: true,
      redirect: true,
      icon: 'plus',
      to: `${appRoot}/register`,
      label: 'Register',
    },
    {
      path: DEFAULT_PATHS.LOGIN,
      exact: true,
      redirect: true,
      icon: 'login',
      to: `${appRoot}/login`,
      label: 'Login',
    },
  ],
  mobileFooterItems: [
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/home`,
      label: 'Home',
      icon: 'home',
      roles: [UserRole.User]
    },
    {
      path: `${appRoot}/dashboard`,
      component: dashboard,
      label: 'Dashboard',
      icon: 'grid-5',
      roles: [UserRole.Director, UserRole.Vendor, UserRole.Admin, UserRole.Coach],
    },
    // {
    //   path: `${appRoot}/profile`,
    //   component: profile,
    //   label: 'My Profile',
    //   icon: 'user',
    //   roles: [UserRole.User]
    // },
    {
      path: `${appRoot}/my-contestants`,
      component: contestants.users,
      label: 'Contestants',
      icon: 'user',
      roles: [UserRole.Admin, UserRole.Contestant]
    },
    // {
    //   path: `${appRoot}/search`,
    //   component: search,
    //   icon: 'search',
    //   label: 'Explore',
    //   roles: [UserRole.User]
    // },
    {
      path: `${appRoot}/events/home`,
      component: events.upcoming,
      label: 'Events',
      icon: 'calendar',
      roles: [UserRole.User]
    },
  ],
  sidebarItems: [
    {
      path: `${appRoot}/admin`,
      label: 'Admin',
      icon: 'dashboard-1',
      subs: [
        { path: '/dashboard', label: 'Admin Dashboard', component: admindashboard,  roles: [UserRole.Admin]},
        // { path: '/all-users', label: 'All Users', component: admin.allusers, roles: [UserRole.Admin]},
        // { path: '/contestant-users', label: 'Contestants', component: admin.contestantusers,  roles: [UserRole.Admin]},
        // { path: '/admin-approvals', label: 'Approvals', component: admin.approvals,  roles: [UserRole.Admin]},
        // { path: '/admin/events', label: 'Events', component: admin.events,  roles: [UserRole.Admin]},
        // { path: '/businesses', label: 'Businesses', component: admin.allbusinesses,  roles: [UserRole.Admin]},
        // { path: '/admin/vendor/categories', label: 'Vendor Categories', component: admin.vendorcategories,  roles: [UserRole.Admin]},
      ],
      roles: [UserRole.Admin]
    },
    {
      path: `${appRoot}/events`,
      label: 'My Events',
      icon: 'crown',
      subs: [
        { path: '/hosting', label: 'Hosting', component: events.list, roles: [UserRole.Director, UserRole.Admin]},
        { path: '/attending', label: 'Attending', component: events.list, roles: [UserRole.Admin] },
        // { path: '/drafts', label: 'Draft', component: events.list, notifications: 2 },
        { path: '/add', label: 'Add Event', component: events.add, roles: [UserRole.Director, UserRole.Admin]},
      ],
    },
   
    {
      path: `${appRoot}/orders`,
      component: orders,
      label: 'Orders',
      icon: 'dashboard-1',
      subs: [
        { path: '/activeorders', label: 'Active Orders', component: orders, notifications: 2, roles: [UserRole.Vendor, UserRole.Admin ] },
        { path: '/pastorders', label: 'Past Order', component: orders, roles: [UserRole.Vendor, UserRole.Admin]  },
        { path: '/orderrequest', label: 'Order Request', component: orders, notifications: 2, roles: [UserRole.Vendor, UserRole.Admin] },
      ],
      roles: [UserRole.Admin]
    },
    {
      path: `${appRoot}/portfolio`,
      component: vendor.portfolio,
      label: 'Vendor Portfolio',
      icon: 'dashboard-1',
      roles: [UserRole.Admin]
    },
    {
      path: `${appRoot}/coach`,
      component: coach.home,
      label: 'Coaching Team',
      icon: 'user',
      roles: [UserRole.Admin],
    },
    {
      path: `${appRoot}/calendar`,
      component: calendar,
      label: 'Calendar',
      icon: 'calendar',
      roles: [UserRole.Admin]
    },
    {
      path: `${appRoot}/tasks`,
      component: tasks,
      label: 'Tasks',
      icon: 'clipboard',
      roles: [UserRole.Admin]
    },
    // {
    //   path: `${appRoot}/discount`,
    //   component: discount,
    //   label: 'Discounts',
    //   icon: 'tag',
    //   roles: [UserRole.Vendor, UserRole.Director]
    // },
    {
      path: `${appRoot}/contacts`,
      component: contacts,
      label: 'Contacts',
      icon: 'tag',
      roles: [UserRole.Admin]
    },
    {
      path: `${appRoot}/settings`,
      component: settings.home,
      label: 'menu.settings',
      icon: 'gear',
      subs: [
        { path: '/general', component: settings.general, hideInMenu: true },
        { path: '/contestant', component: settings.contestant, hideInMenu: true },
        { path: '/business', component: settings.business, hideInMenu: true },
        { path: '/account', component: settings.account, hideInMenu: true },
      ],
      roles: [UserRole.User]
    },
  ],
  adminDashboardItems: [
    {
      path: `${appRoot}/admindashboard`,
      component: admindashboard,
      label: 'Dashboard',
      icon: 'dashboard-1',
      roles: [UserRole.Admin]
    },
    {
      path: `${appRoot}/Users`,
      component: users,
      label: "All Users",
      icon: 'user',
      roles: [UserRole.Admin]
    },
    {
      path: `${appRoot}/contacts`,
      component: contacts,
      label: 'Contacts',
      icon: 'tag',
      roles: [UserRole.Admin]
    },
    {
      path: `${appRoot}/settings`,
      component: settings.home,
      label: 'menu.settings',
      icon: 'gear',
      subs: [{ path: '/general', component: settings.general, hideInMenu: true }],
      roles: [UserRole.Admin]
    },
  ],
  additionalItems: [
    {
      path: `${appRoot}/discount`,
      component: discount,
      label: 'menu.discount',
      icon: 'tag',
      roles: [UserRole.Admin]
    },
    {
      path: `${appRoot}/about-us`,
      component: aboutus,
      label: 'menu.aboutus',
      icon: 'tag',
    },
    {
      path: `${appRoot}/sample-pageant-details`,
      component: events.sample,
      label: 'events.sample',
      icon: 'tag',
    },
    {
      path: `${appRoot}/membership`,
      icon: 'invoice',
      component: membership.home,
      label: 'menu.membership',
      subs: [

        { path: '/pricing', label: 'Pricing', component: membership.pricing },
      ],
    },
    {
      path: `${appRoot}/membership/select`,
      icon: 'tag',
      component: membership.select,
    
    },
    {
      path: `${appRoot}/home`,
      component: home,
      label: 'Home',
      icon: 'home',
    },

    {
      path: `${appRoot}/newsfeed`,
      component: newsfeed,
      label: 'News Feed',
      icon: 'home',
    },
    {
      path: `${appRoot}/marketplace`,
      component: marketplace,
      label: 'Marketplace',
      icon: 'home',
      roles: [UserRole.Admin]
    },
    {
      path: `${appRoot}/vendor`,
      subs: [
        { path: '/portfolio', label: 'Portfolio', component: vendor.portfolio},
      ],
      roles: [UserRole.Admin]
    },
    {
      path: `${appRoot}/products-and-services`,
      subs: [
        { path: '/detail', label: 'productsservices.detail', component: productsservices.detail},
      ],
      roles: [UserRole.Admin]
    },
    {
      path: `${appRoot}/order`,
      subs: [
        { path: '/details', label: 'order.detail', component: order.details},
      ],
      roles: [UserRole.Admin]
    },
    {
      path: `${appRoot}/members`,
      subs: [
        { path: '/home', label: 'Members', component: members.home, roles: [UserRole.User] },
        { path: '/:id/:name/profile', component: members.profile, roles: [UserRole.Director, UserRole.Admin, UserRole.Contestant, UserRole.Vendor, UserRole.Coach, UserRole.User] },
      ],
      // roles: [UserRole.Director, UserRole.Admin, UserRole.Contestant] 
    },
    {
      path: `${appRoot}/contestants`,
      subs: [
        { path: '/public-portfolio/:id/:name', label: 'Contestants', component: contestants.publicprofile },
        { path: '/home', label: 'Contestants', component: contestants.home },
      ],
    },
    {
      path: `${appRoot}/events/:id/:name`,
      // component: events.home,
      label: 'Events',
      icon: 'book',
      subs: [
        { path: '/manage', label: 'Manage', component: events.manage, roles: [UserRole.Director, UserRole.Admin] },
        { path: '/add', label: 'Add New', component: events.add, roles: [UserRole.Director, UserRole.Admin] },
        { path: '/detail', label: 'Details', component: events.detail, roles: [UserRole.User] },
        { path: '/results', label: 'Results', component: events.results, roles: [UserRole.User] },
        { path: '/registrationdashboard', label: 'Registration Dashboard', component: events.registrationdashboard, roles: [UserRole.Director, UserRole.Admin] },
        { path: '/scorehome', label: 'Scoring and Tallying', component: events.scoringhome, roles: [UserRole.Director, UserRole.Admin] },
        // { path: '/detail', label: 'Details', component: events.detail, roles: [UserRole.Contestant, UserRole.Director, UserRole.Vendor, UserRole.Admin, UserRole.Coach] },
      ],
    },

    {
      path: `${appRoot}/events/home`,
      component: events.upcoming,
      label: 'Events',
      icon: 'book',
    },
    {
      path: `${appRoot}/events/past`,
      component: events.past,
      label: 'Past Events',
      icon: 'book',
    },
    {
      path: `${appRoot}/terms-and-conditions`,
      component: termsandconditions,
    },
    {
      path: `${appRoot}/privacy-policy`,
      component: privacypolicy,
    },
    {
      path: `${appRoot}/data-deletion-instructions`,
      component: datadeletioninstructions,
    },
    {
      path: `${appRoot}/business`,
      subs: [
        { path: '/home', label: 'Home', component: business.home},
        { path: '/:id/:name/details', label: 'Details', component: business.details},
      ],

    },
    {
      path: `${appRoot}/search`,
      component: search,
      label: 'Search',
      // roles: [UserRole.User]
    },
    {
      path: `${appRoot}/chat`,
      component: chat,
      label: 'Chat',
      icon: 'messages',
      roles: [UserRole.Admin]
    },
    {
      path: `${appRoot}/resources`,
      subs: [
        { path: '/dashboard', label: 'Get Started', component: resources.dashboard, roles: [UserRole.Admin] },
        { path: '/hello-tiara-faq', label: 'FAQ', component: resources.hellotiarafaq },
        { path: '/pageant-knowledge-base', label: 'FAQ', component: resources.pageantknowledgebase, roles: [UserRole.Admin] },
        { path: '/pageant-dictionary', label: 'Dictionary', component: resources.dictionary, roles: [UserRole.Admin] },
      ],
    },
    {
      path: `${appRoot}/profile`,
      component: profile,
      roles: [UserRole.User]
    },
    {
      path: 'order/details', label: 'Order Details', component: order.details, roles: [UserRole.Admin]
    },
    {
      path: `${appRoot}/resources/guides/all-about-glitz`,
      component: guides.guide01,
      label: 'All About Glitz',
      roles: [UserRole.Admin]
    },
    {
      path: `${appRoot}/interface/spinners`,
      component: spinner,
      label: 'Spinners',
      roles: [UserRole.Admin]
    },
    {
      path: `${appRoot}/contestant`,
      component: contestant,
      label: 'My Contestants',
      icon: 'online-class',
      subs: [
        { path: '',  component: contestant.profile },
        { path: '/add', label: 'Add New', component: contestant.profile },
        { path: '/event-management', label: 'Event Management', component: contestant.eventmanage },
        { path: '/profile/:name', component: contestant.profile, roles: [UserRole.Director, UserRole.Admin, UserRole.Contestant]  },

        // { path: '/public-profile/:id/:name', label: 'Contestants', component: contestant.publicprofile, roles: [UserRole.User]  },
      ],
      roles: [UserRole.Contestant, UserRole.Admin]
    },
  ],
  resourceGuides: [
    {
      path: `${appRoot}/resources/guides/all-about-glitz`,
      component: guides.guide01,
      label: 'All About Glitz',
      roles: [UserRole.Admin]
    },
  ],
};
export default routesAndMenuItems;
