/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BooleanResponseWrapper,
  PageantAvailableTitleModel,
  PageantAvailableTitleModelResponseWrapper,
  RegisteredContestantSelectedTitleBaseModel,
  RegisteredContestantSelectedTitleBaseModelResponseWrapper,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class PageantAvailableTitle<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags PageantAvailableTitle
   * @name GetPageantAvailableTitleId
   * @request GET:/PageantAvailableTitle/{pageantAvailableTitleId}
   */
  getPageantAvailableTitleId = (pageantAvailableTitleId: number, params: RequestParams = {}) =>
    this.request<PageantAvailableTitleModel, any>({
      path: `/PageantAvailableTitle/${pageantAvailableTitleId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PageantAvailableTitle
   * @name DeletePageantAvailableTitle
   * @request DELETE:/PageantAvailableTitle/{pageantAvailableTitleId}
   */
  deletePageantAvailableTitle = (pageantAvailableTitleId: number, params: RequestParams = {}) =>
    this.request<BooleanResponseWrapper, any>({
      path: `/PageantAvailableTitle/${pageantAvailableTitleId}`,
      method: 'DELETE',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PageantAvailableTitle
   * @name SavePageantAvailableTitle
   * @request POST:/PageantAvailableTitle/Save
   */
  savePageantAvailableTitle = (data: PageantAvailableTitleModel, params: RequestParams = {}) =>
    this.request<PageantAvailableTitleModelResponseWrapper, any>({
      path: `/PageantAvailableTitle/Save`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PageantAvailableTitle
   * @name SaveAllCreate
   * @request POST:/PageantAvailableTitle/{pageantId}/saveAll
   */
  saveAllCreate = (pageantId: number, data: PageantAvailableTitleModel[], params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/PageantAvailableTitle/${pageantId}/saveAll`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags PageantAvailableTitle
   * @name AssignAward
   * @request POST:/PageantAvailableTitle/AwardAssignment
   */
  assignAward = (data: RegisteredContestantSelectedTitleBaseModel, params: RequestParams = {}) =>
    this.request<RegisteredContestantSelectedTitleBaseModelResponseWrapper, any>({
      path: `/PageantAvailableTitle/AwardAssignment`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PageantAvailableTitle
   * @name DeleteAwardAssignment
   * @request DELETE:/PageantAvailableTitle/AwardAssignment/{awardAssignmentId}
   */
  deleteAwardAssignment = (awardAssignmentId: number, params: RequestParams = {}) =>
    this.request<BooleanResponseWrapper, any>({
      path: `/PageantAvailableTitle/AwardAssignment/${awardAssignmentId}`,
      method: 'DELETE',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PageantAvailableTitle
   * @name PackageAssociation
   * @request POST:/PageantAvailableTitle/PackageAssociation
   */
  packageAssociation = (
    data: number[],
    query?: {
      /** @format int32 */
      titleId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/PageantAvailableTitle/PackageAssociation`,
      method: 'POST',
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags PageantAvailableTitle
   * @name DeletePackageAssociation
   * @request DELETE:/PageantAvailableTitle/PackageAssociation/{packageAssociationId}
   */
  deletePackageAssociation = (packageAssociationId: number, params: RequestParams = {}) =>
    this.request<BooleanResponseWrapper, any>({
      path: `/PageantAvailableTitle/PackageAssociation/${packageAssociationId}`,
      method: 'DELETE',
      format: 'json',
      ...params,
    });
}
