import { DirectorUsers } from "API/Generated/DirectorUsers";
import { makeAutoObservable } from "mobx"
import { makePersistable } from 'mobx-persist-store';
import { CoachFormModel, GetStartedFormModel, MembershipModel, VendorFormModel } from "types/registration/RegistrationTypes";
import { ContestantUserBaseModel, ContestantUserModel, DirectorUserBaseModel, PictureModel, RegisterModel, UserModel, VendorUserBaseModel } from '../../API/Generated/data-contracts'
import { Users } from '../../API/Generated/Users'
import { Account } from '../../API/Generated/Account'
import { GetUserStore, UserStore } from "SessionState/User/UserStore";

export class UserRegistrationStore {

    // Services
    UserService: Users = new Users();
    AccountService: Account = new Account();
    DirectorService: DirectorUsers = new DirectorUsers();

    // Properties
    PendingSaveUser: UserModel
    PendingSaveMembership: MembershipModel
    PendingSaveContestants: ContestantUserBaseModel[]
    RegistrationUser: RegisterModel

    //Stores
    UserStore: UserStore;

    // Constructors
    constructor(pendingSaveUser: UserModel, pendingSaveMembership: MembershipModel, pendingSaveContestants: ContestantUserBaseModel[], registrationUser: RegisterModel) {
        makeAutoObservable(this);
        // makePersistable(this, { name: 'UserRegistrationStore', properties: ['PendingSaveUser', 'PendingSaveMembership', 'PendingSaveContestants', 'RegistrationUser'] });
        this.PendingSaveUser = pendingSaveUser;
        this.PendingSaveMembership = pendingSaveMembership;
        this.PendingSaveContestants = pendingSaveContestants;
        this.RegistrationUser = registrationUser;
        this.UserStore = GetUserStore();
    }

    // Actions
    async setFirstName(firstName: string) {
        try {
            this.PendingSaveUser.firstName = firstName;
        }
        catch (error) {
            // TODO: Call Log API endpoint
            console.log("Error: " + JSON.stringify(error));
        }
    }
    async setRegistrationUser(model: RegisterModel | any) {
        try { this.RegistrationUser = model }
        catch (error) {
            console.log(`Error: ${error}`);
        }
    }
    async registerUser(): Promise<UserModel|undefined> {
        try {
            let apiResult = await this.AccountService.registerCreate(this.RegistrationUser);
            if (apiResult.data) {
                if (apiResult.data.isSuccessful) {
                    return apiResult.data.data;
                }
                return undefined;
            }
        } catch (error) {
            console.log(`Error: ${JSON.stringify(error)}`);
        }
        return undefined;
    }
    async loginUser(email: string, password: string): Promise<string> {
        try {
            let apiResult = await this.AccountService.loginCreate({ email, password })
            if (apiResult.data.succeeded) {
                await this.UserStore.setLoggedInUser()
                return "Success"
            }
            return "Error Logging in"
        }
        catch (error) {
            return "Error Logging in"
        }
        
    }
    async logoutUser() {
        try {
            await this.AccountService.logoutCreate();
        } catch (error) {
            return "Error Logging Out"
        }
    }

    async setPendingSaveUser(model: UserModel | any) {
        try {
            this.PendingSaveUser = model;
        }
        catch (error) {
            // TODO: Call Log API endpoint
            console.log(`Error: ${error}`);
        }
    }
    async updateUser(user: UserModel) {
        try {
            user.contestantUserAccounts = null;
            let apiResult = await this.UserService.saveUser(user);

            if (apiResult.data) {
                if (apiResult.data.isSuccessful) {
                    return "User updated successfully!";
                }
                return apiResult.data.exception!!;
            }
        }
        catch (error) {
            console.log(`Error: ${JSON.stringify(error)}`);
            return "Error updating user.";
        }
    }

    async updateUserProfile(user: UserModel) {
        try {
            let currentUser = {...this.UserStore.CurrentUser};
            currentUser.firstName = user.firstName;
            currentUser.lastName = user.lastName;
            // currentUser.email = user.email;
            currentUser.phoneNumber = user.phoneNumber;
            // currentUser.address = user.address;
            currentUser.additionalEmail = user.additionalEmail;
            currentUser.facebookLink = user.facebookLink;
            currentUser.contestantUserAccounts = null;
            currentUser.profilePic = user.profilePic;
            currentUser.userAbout = user.userAbout;
            currentUser.city = user.city;
            
            let apiResult = await this.UserService.saveUser(currentUser);
            if (apiResult.data) {
                if (apiResult.data.isSuccessful) {
                    this.UserStore.refreshCurrentUser();
                    return "User updated successfully!";

                }
                return apiResult.data.exception!!;
            }
        }
        catch (error) {
            console.log(`Error: ${JSON.stringify(error)}`);
            return "Error updating user.";
        }
    }

    async resetUserPassword(userId: number, newPassword: string) {
        try {
            let result = await this.AccountService.resetPasswordCreate({userId, newPassword});
            if (result.data) {
                if (result.data.isSuccessful) {
                    return "Password reset successfully!";
                }
                return result.data.exception!!;
            }
        } catch (error) {
            console.log(`Error: ${JSON.stringify(error)}`);
        }
    }

    
    async addUser(): Promise<string> {
        console.log("PendingSaveUser: " + JSON.stringify(this.PendingSaveUser));
        try {
            let apiResult = await this.UserService.addUser(this.PendingSaveUser);
            if (apiResult.data) {
                if (apiResult.data.isSuccessful) {
                    this.PendingSaveUser = {};
                    return "Account created successfully!";
                }
                
                return apiResult.data.exception!!;
            }
        }
        catch (error) {
            // TODO: Call Log API endpoint
            console.log(`Error: ${JSON.stringify(error)}`);
            return `Error adding User.`;
        }
        return "Error adding user, please try again.";
    }
    async addDirector(): Promise<string> {
        try {
            let apiResult = this.PendingSaveMembership.director && await this.DirectorService.addDirectorUser(this.PendingSaveMembership.director)
            if (apiResult?.data) {
                if (apiResult.data.isSuccessful) {
                    return "Director User added successfully!!";
                }
                return apiResult.data.exception!!;
            }
        }
        catch (error) {
            //TODO: Call Log API endpoint
            console.log(`Error: ${JSON.stringify(error)}`);
            return "Error adding director.";
        }
        return "Error adding director, please try again.";
    }

    async setMembershipGetStarted(model: GetStartedFormModel) {
        try {
            this.PendingSaveMembership.getStarted = model;
        }
        catch (error) {
            //TODO: Call Log API endpoint
            console.log(`Error: ${JSON.stringify(error)}`);
        }
    }
    async setMembershipDirector(model: DirectorUserBaseModel) {
        try {
            this.PendingSaveMembership.director = model;
        }
        catch (error) {
            //TODO: Call Log API endpoint
            console.log(`Error: ${JSON.stringify(error)}`);
        }
    }
    async setMembershipVendor(model: VendorFormModel) {
        try {
            this.PendingSaveMembership.vendor = model;
        }
        catch (error) {
            //TODO: Call Log API endpoint
            console.log(`Error: ${JSON.stringify(error)}`);
        }
    }
    async setMembershipCoach(model: CoachFormModel) {
        try {
            this.PendingSaveMembership.coach = model;
        }
        catch (error) {
            //TODO: Call Log API endpoint
            console.log(`Error: ${JSON.stringify(error)}`);
        }
    }
    async getPendingSaveMembership(): Promise<MembershipModel> {
        return this.PendingSaveMembership;
    }
    async setPendingSaveContestants(models: ContestantUserBaseModel[]) {
        try {
            this.PendingSaveContestants = models;
        }
        catch (error) {
            //TODO: Call Log API endpoint
            console.log(`Error: ${JSON.stringify(error)}`)
        }
    }
    async addPendingContestant(model: ContestantUserBaseModel) {
        try {
            if (!this.PendingSaveContestants.find(x => JSON.stringify(x) === JSON.stringify(model))) {
                this.PendingSaveContestants.push(model);
            }
        }
        catch (error) {
            //TODO: Call Log API endpoint
            console.log(`Error: ${JSON.stringify(error)}`);
        }
    }
    async modifyExistingContestant(model: ContestantUserBaseModel, index: number) {
        console.log("Modifying Contestant", model);
        try {
            this.PendingSaveContestants[index] = model;
        }
        catch (error) {
            //TODO: Call Log API endpoint
            console.log(`Error: ${JSON.stringify(error)}`);
        }
    }
    async deleteContestantByIndex(index: number) {
        try {
            this.PendingSaveContestants = this.PendingSaveContestants.filter((x, i) => i !== index);
        }
        catch (error) {
            //TODO: Call Log API endpoint
            console.log(`Error: ${JSON.stringify(error)}`);
        }
    }

    async saveImage(image: PictureModel): Promise<boolean> {
        try {
            let currentUser = {...this.UserStore.CurrentUser};
            currentUser.contestantUserAccounts = null;
            currentUser.profilePic = {...image};
            let apiResult = await this.UserService.saveUser(currentUser);
            if (apiResult.data) {
                if (apiResult.data.isSuccessful) {
                    return true;
                }
                // return apiResult.data.exception!!;
                return false;
            }
            return false;
        }
        catch (error) {
            console.log(`Error: ${JSON.stringify(error)}`);
            return false;
        }
    }

// Actions
// In your UserRegistrationStore class
async forgotPassword(email: string): Promise<string> {
    try {
      const result = await this.AccountService.forgotPasswordCreate({ email });
  
      // Cast `data` to the expected type
      const data = result.data as unknown as { isSuccessful: boolean; resetLink?: string; exception?: string };
  
      if (data) {
        if (data.isSuccessful) {
          return data.resetLink ?? "If an account with that email exists, you will receive an email with password reset instructions.";
        }
        return data.exception ?? "An error occurred while processing the request.";
      } else if (result.error) {
        return "There was an error processing your request.";
      }
    } catch (error) {
      return "There was an error processing your request.";
    }
    return "There was an error processing your request.";
  }
  
  


  async forgotPasswordPhone(phone: string): Promise<string> {
    try {
      const result = await this.AccountService.forgotPasswordPhoneCreate({ phone });
  
      const data = result.data as any; // Use `any` if necessary
  
      if (data) {
        if (data.isSuccessful) {
          console.log(`Reset link: ${data.resetLink}`);
          return data.resetLink ?? "If an account with that phone number exists, you will receive a text with password reset instructions.";
        }
        console.error(`Forgot password phone error: ${data.exception}`);
        return data.exception ?? "An error occurred while processing the request.";
      } else if (result.error) {
        console.error(`API Error: ${JSON.stringify(result.error)}`);
        return "There was an error processing your request.";
      }
    } catch (error) {
      console.error(`Error in forgotPasswordPhone: ${JSON.stringify(error)}`);
      return "There was an error processing your request.";
    }
    return "There was an error processing your request.";
  }
  

  async resetPasswordWithToken(email: string, token: string, newPassword: string): Promise<string> {
    try {
      const result = await this.AccountService.resetPasswordWithTokenCreate({ email, token, newPassword });
  
      const data = result.data as any; // Use `any` if necessary
  
      if (data && data.isSuccessful) {
        console.log('Password reset successful:', data);
        return data.exception || 'Password has been reset successfully.';
      }
  
      console.error('Reset password error:', data?.exception, result.error);
      return data?.exception || 'There was an error processing your request.';
    } catch (error) {
      console.error('Error in resetPasswordWithToken:', error);
      return 'There was an error processing your request.';
    }
  }
  




  
  
  



}

let userRegistrationStore: UserRegistrationStore;

export function GetUserRegistrationStore() {
    if (!userRegistrationStore) {
        userRegistrationStore = new UserRegistrationStore({}, {}, [], {});
    }
    return userRegistrationStore;
}