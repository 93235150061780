/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ReceivedScore, ReceivedScoreBaseModel } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class ReceivedScores<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags ReceivedScores
   * @name SaveScoreCreate
   * @request POST:/ReceivedScores/SaveScore
   */
  saveScoreCreate = (data: ReceivedScoreBaseModel[], params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/ReceivedScores/SaveScore`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ReceivedScores
   * @name BulkSaveCreate
   * @request POST:/ReceivedScores/bulk-save/{pageantId}
   */
  bulkSaveCreate = (pageantId: number, data: ReceivedScoreBaseModel[], params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/ReceivedScores/bulk-save/${pageantId}`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ReceivedScores
   * @name GetByContestantDetail
   * @request GET:/ReceivedScores/GetByContestant/{contestantId}
   */
  getByContestantDetail = (contestantId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/ReceivedScores/GetByContestant/${contestantId}`,
      method: 'GET',
      ...params,
    });
  /**
   * No description
   *
   * @tags ReceivedScores
   * @name GetTotalScoresForContestantByAocDetail
   * @request GET:/ReceivedScores/GetTotalScoresForContestantByAOC/{contestantId}
   */
  getTotalScoresForContestantByAocDetail = (contestantId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/ReceivedScores/GetTotalScoresForContestantByAOC/${contestantId}`,
      method: 'GET',
      ...params,
    });
  /**
   * No description
   *
   * @tags ReceivedScores
   * @name GetTotalScoresWithDropsForContestantByAocDetail
   * @request GET:/ReceivedScores/GetTotalScoresWithDropsForContestantByAOC/{contestantId}
   */
  getTotalScoresWithDropsForContestantByAocDetail = (contestantId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/ReceivedScores/GetTotalScoresWithDropsForContestantByAOC/${contestantId}`,
      method: 'GET',
      ...params,
    });
  /**
   * No description
   *
   * @tags ReceivedScores
   * @name GetTotalScoresWithDropsForContestantByAocAllDetail
   * @request GET:/ReceivedScores/GetTotalScoresWithDropsForContestantByAOCAll/{contestantId}
   */
  getTotalScoresWithDropsForContestantByAocAllDetail = (contestantId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/ReceivedScores/GetTotalScoresWithDropsForContestantByAOCAll/${contestantId}`,
      method: 'GET',
      ...params,
    });
  /**
   * No description
   *
   * @tags ReceivedScores
   * @name GetFilteredTotalScoresWithDropsForContestantDetail
   * @request GET:/ReceivedScores/GetFilteredTotalScoresWithDropsForContestant/{contestantId}
   */
  getFilteredTotalScoresWithDropsForContestantDetail = (
    contestantId: number,
    query?: {
      aocScoringCategoryIds?: number[];
      aocIds?: number[];
    },
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/ReceivedScores/GetFilteredTotalScoresWithDropsForContestant/${contestantId}`,
      method: 'GET',
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags ReceivedScores
   * @name GetByJudgeDetail
   * @request GET:/ReceivedScores/GetByJudge/{judgeId}
   */
  getByJudgeDetail = (judgeId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/ReceivedScores/GetByJudge/${judgeId}`,
      method: 'GET',
      ...params,
    });
  /**
   * No description
   *
   * @tags ReceivedScores
   * @name UpdateUpdate
   * @request PUT:/ReceivedScores/Update/{scoreId}
   */
  updateUpdate = (scoreId: number, data: ReceivedScore, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/ReceivedScores/Update/${scoreId}`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ReceivedScores
   * @name DeleteDelete
   * @request DELETE:/ReceivedScores/Delete/{scoreId}
   */
  deleteDelete = (scoreId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/ReceivedScores/Delete/${scoreId}`,
      method: 'DELETE',
      ...params,
    });
  /**
   * No description
   *
   * @tags ReceivedScores
   * @name BulkCreateOrUpdateCreate
   * @request POST:/ReceivedScores/BulkCreateOrUpdate
   */
  bulkCreateOrUpdateCreate = (data: ReceivedScoreBaseModel[], params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/ReceivedScores/BulkCreateOrUpdate`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
}
